import {defineStore} from 'pinia'
import {Preferences} from '@capacitor/preferences'
import {emitirSnack} from '@/store/GlobalComponents'
import router from '@/router'

export const useSessionStore = defineStore('session', {
  state: () => ({
    token: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
    payload: (state) => {
      if (!state.token) return null
      const partes = state.token.split('.')
      if (partes.length !== 3) return null
      try {
        return JSON.parse(window.atob(partes[1]))
      } catch (e) {
        return null
      }
    },
  },
  actions: {
    logout() {
      if (this.token) {
        this.token = null
        emitirSnack({color: 'success', text: 'Você foi desconectado'})
      }
      return router.replace({name: 'Login'})
    },
  },
})

export const restoreSavedSession = async (pinia = null) => {
  const { value } = await Preferences.get({ key: 'pinia_session' })
  const store = useSessionStore(pinia)
  if (value) store.token = value
  store.$subscribe((mutation, state) => {
    if (state.token) Preferences.set({ key: 'pinia_session', value: state.token })
    else Preferences.remove({ key: 'pinia_session' })
  }, { detached: true })
}
