
const routes = [
  {
    path: '/auth',
    component: () => import('../layouts/AuthLayout.vue'),
    meta: { requiresNoAuth: true },
    children: [
      { path: 'login', name: 'Login', alias: '/login', component: () => import('../views/auth/LoginPage.vue') },
      { path: 'criar-conta', name: 'criar-conta', component: () => import('../views/auth/CriarConta.vue') },
      { path: 'confirmar-email', name: 'confirmar-email', component: () => import('../views/auth/ConfirmarEmail.vue') },
      { path: 'esqueci-senha', name: 'esqueci-senha', component: () => import('../views/auth/EsqueciSenha.vue') },
    ],
  },

  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      { path: '', name: 'home', alias: '/home',component: () => import('../views/HomePage.vue') },
      { path: 'config', name: 'Configuracoes', component: () => import('../views/ConfiguracoesPage.vue') },
      { path: 'alterar-senha', name: 'alterar-senha', component: () => import('../views/usuario/AlterarSenha.vue') },
      { path: 'palavrapresidente/cofeci', name: 'Palavra-Presidente-Cofeci', component: () => import('../views/utilidades/PalavraPresidenteCofeci.vue') },
      { path: 'palavrapresidente/creci', name: 'Palavra-Presidente-Creci', component: () => import('../views/utilidades/PalavraPresidenteCreci.vue') },
      { path: 'carp', name: 'Carp', component: () => import('../views/CarpDigital.vue') },
      { path: 'recadastrar', name: 'recadastrar', component: () => import('../views/recad/RecadastrarPf.vue') },
      { path: 'recad', name: 'MenuRecad', component: () => import('../views/recad/RecadMenu.vue') },
      { path: 'recad/historico', name: 'recad-historico', component: () => import('../views/recad/RecadHistorico.vue') },
      { path: 'contas', name: 'Contas', component: () => import('../views/usuario/MinhasContas.vue') },
      { path: 'utilidades', name: 'Utilidades', component: () => import('../views/utilidades/UtilidadesMenu.vue') },
      { path: 'utilidades/carp', name: 'Utilidades-Carp', component: () => import('../views/utilidades/CrecimsCarp.vue') },
      { path: 'utilidades/legislacao', name: 'Utilidades-Legislacao', component: () => import('../views/utilidades/LegislacaoPage.vue') },
      { path: 'utilidades/portalcreci', name: 'utilidades-portalcreci', component: () => import('../views/utilidades/PortalCreci.vue') },
      { path: 'cartaovirtual', name: 'CartaoVirtual', component: () => import('../views/utilidades/CartaoDeVisita.vue') },
      { path: 'email', name: 'Email', meta: {requiresCnet: true}, component: () => import('../views/utilidades/EmailInstitucional.vue') },
      { path: 'revistacofeci', name: 'RevistaCofeci', component: () => import('../views/utilidades/RevistaCofeci.vue') },
      { path: 'financeiro', name: 'Financeiro', meta: {requiresCnet: true}, component: () => import('../views/financeiro/FinanceiroMenu.vue') },
      { path: 'financeiro/acordos', name: 'financeiro-acordos', meta: {requiresCnet: true}, component: () => import('../views/financeiro/FinanceiroAcordos.vue') },
      { path: 'financeiro/seletor', name: 'financeiro-seletor', meta: {requiresCnet: true}, component: () => import('../views/financeiro/CobrancasSeletor.vue') },
      { path: 'financeiro/cobrancas/:cnpj?', name: 'financeiro-cobrancas', meta: {requiresCnet: true}, component: () => import('../views/financeiro/CobrancasLista.vue') },
      { path: 'financeiro/cartao/:cnpj?', name: 'financeiro-cartao', meta: {requiresCnet: true}, component: () => import('../views/financeiro/CartaoCredito.vue') },
      { path: 'financeiro/parcelar', name: 'financeiro-parcelar', meta: {requiresCnet: true}, component: () => import('../views/financeiro/ParcelarCobranca.vue') },
      { path: 'coaf', component: () => import('../views/utilidades/coaf/CoafMenu.vue')},
      { path: 'coaf/seletor', component: () => import('../views/utilidades/coaf/CoafSeletor.vue') },
      { path: 'coaf/comunicarno/:cnpj?', name: 'coaf-comunicarno', component: () => import('../views/utilidades/coaf/CoafComunicarNO.vue')},
      { path: 'coaf/seletorcomprovantes', component: () => import('../views/utilidades/coaf/CoafSeletorComprovantes.vue') },
      { path: 'coaf/comprovantes/:cnpj?', name: 'coaf-comprovantes', component: () => import('../views/utilidades/coaf/CoafComprovantes.vue')},
      { path: 'coaf/ajuda', component: () => import('../views/utilidades/coaf/CoafAjuda.vue')},
      { path: 'mensagens', meta: {requiresCnet: true}, component: () => import('../views/MensagensPage.vue')},
    ]
  },

  {
    path: '*',
    name: '404',
    component: () => import('../views/NotFound.vue'),
  },
]

export default routes
