import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { pinia } from '@/plugins/pinia'
import { useSessionStore } from '@/store/Session'
import { useFichaStore } from '@/store/Ficha'
import { useComponentsStore } from '@/store/GlobalComponents'

Vue.use(VueRouter)

const Router = new VueRouter({
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 })
})

Router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresNoAuth = to.matched.some(record => record.meta.requiresNoAuth)
  const requiresCnet = to.matched.some(record => record.meta.requiresCnet)
  const sessionStore = useSessionStore(pinia)
  const fichaStore = useFichaStore(pinia)
  const componentsStore = useComponentsStore(pinia)

  if (requiresAuth && !sessionStore.isAuthenticated) next('/auth/login')
  else if (requiresNoAuth && sessionStore.isAuthenticated) next('/')
  else if (requiresCnet && fichaStore.cadastro?.fonte !== 'I2BR') {
    componentsStore.showDialog('O CRECI da sua região não tem parceria com esta seção do aplicativo.')
    next(false)
  }
  else next()
})

export default Router
