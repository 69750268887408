<script>
import {defineComponent} from 'vue'
import {Capacitor} from '@capacitor/core'
import {Preferences} from '@capacitor/preferences'
import {StatusBar, Style} from '@capacitor/status-bar'
import GlobalComponents from '@/components/GlobalComponents.vue'
export default defineComponent({
  components: {GlobalComponents},
  async mounted() {
    Preferences.get({key: 'cache_theme'}).then(v => {
      if (v.value !== null) this.$vuetify.theme.dark = v.value === '1'
    })

    if (Capacitor.isNativePlatform()) {
      import('@capacitor/push-notifications').then(({ PushNotifications }) => {
        PushNotifications.addListener('registration', async token => {
          const {httpSilent} = await import('@/plugins/axios')
          const {useSessionStore} = await import('@/store/Session')
          if (useSessionStore) {
            const sessionStore = useSessionStore()
            if (sessionStore.isAuthenticated) httpSilent.post('/usuario/push-subscribe', {token: token.value})
          }
        })
      })
    }
  },
  methods: {
    onThemeChange() {
      document.querySelector('meta[name="theme-color"]').setAttribute('content',  this.$vuetify.theme.dark ? '#272727' : '#1976D2');
      if (Capacitor.isNativePlatform()) StatusBar.setStyle({style: Style.Dark})
      if (Capacitor.getPlatform() === 'android') StatusBar.setBackgroundColor({color: (this.$vuetify.theme.dark ? '#272727' : '#1976D2')})
      Preferences.set({key: 'cache_theme', value: (this.$vuetify.theme.dark ? '1' : '0')})
    },
  },
  computed: {
    darkMode() {return this.$vuetify.theme.dark},
  },
  watch: {
    darkMode() {this.onThemeChange()},
  },
})
</script>

<template>
  <v-app>
    <router-view></router-view>
    <GlobalComponents />
  </v-app>
</template>

<style>
.v-application .v-app-bar {
  padding-top: env(safe-area-inset-top);
  box-sizing: content-box;
}
.v-application .v-navigation-drawer {
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
}
.v-application main.v-main {
  padding-top: calc(48px + env(safe-area-inset-top)) !important;
}

.v-application--is-ltr .v-data-footer__select div.v-select {
  margin-left: 4px;
}
.v-application--is-ltr div.v-data-footer__select {
  margin-right: auto;
  margin-left: 6px;
}
.v-application--is-ltr div.v-data-footer__pagination {
  margin-left: 6px;
  margin-right: 0;
}
thead.v-data-table-header th {
  white-space: nowrap;
}
.v-dialog:not(.v-dialog--fullscreen) {
  margin: .8rem !important;
}
</style>
