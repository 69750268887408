import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { pinia } from '@/plugins/pinia'
import { checkVersion } from '@/plugins/persistance'
import { restoreSavedSession } from '@/store/Session'
import { restoreSavedFicha } from '@/store/Ficha'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './plugins/theMask'
import './plugins/pinchZoom'

Vue.config.productionTip = false

try {
  await checkVersion()
  await restoreSavedSession(pinia)
  await restoreSavedFicha(pinia)
} finally {
  new Vue({
    pinia,
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}
